
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { dealsStatuses } from '@/definitions/deals/data'

export default defineComponent({
  components: {
    TmModal,
    TmFormLine,
    TmButton,
  },
  props: {
    stageName: {
      type: String,
    },
  },
  setup() {
    const newStage = ref(dealsStatuses[0])
    const option = ref('delete')

    return {
      option,
      newStage,
      dealsStatuses,
    }
  },
})
