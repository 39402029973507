import type { SortingItemType } from '@/definitions/_general/tables/topFilter'
import type { PersonType, StatusType } from '@/definitions/shared/types'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'
import type { KanbanColumnType, KanbanCheckListItem, KanbanAttachmentType } from '@/definitions/shared/kanban/types'
import type { DealsCardStagesType, DealsDetailsProgressItem, DealsDetailsTimelineItem } from '@/definitions/deals/types'
import type { TemplateType } from '@/components/modals/shared/SelectTemplateModal.vue'
import { useModals } from '@/compositions/modals'
import { ref } from 'vue'

const { openModal } = useModals()
const openTemplateModal = () => {
  openModal('selectTemplate', {
    templates: dealsTemplates,
    title: 'Select a new pipeline template',
  })
}

export const sortingOptions: SortingItemType[] = [
  {
    name: 'Date created (Oldest first)',
  },
  {
    name: 'Date created (Newest first)',
    divider: true,
  },
  {
    name: 'Deal title (A-Z)',
  },
  {
    name: 'Deal title (Z-A)',
    divider: true,
  },
  {
    name: 'Estimated value (High to Low)',
  },
  {
    name: 'Estimated value (Low to High)',
  },
]

type DealStatusType = StatusType<DealsCardStagesType> & {
  probability: string;
}

export const dealsStatuses: DealStatusType[] = [
  {
    name: 'qualified',
    color: 'cyan',
    outline: true,
    probability: '10%',
  },
  {
    name: 'contact made',
    color: 'blue',
    outline: true,
    probability: '30%',
  },
  {
    name: 'proposal made',
    color: 'violet',
    outline: true,
    probability: '50%',
  },
  {
    name: 'negotiation started',
    outline: true,
    color: 'orange',
    probability: '70%',
  },
  {
    name: 'won',
    color: 'green',
    outline: false,
    isDefault: true,
    probability: '',
  },
  {
    name: 'lost',
    color: 'red',
    outline: false,
    isDefault: true,
    probability: '',
  },
]

export const dealsColumnsMock: KanbanColumnType[] = [
  {
    id: 'column-1',
    title: 'Qualified',
    name: 'qualified',
    color: rgbToHex(styleVars.brown500),
    items: [],
    collapsed: false,
    index: 0,
    status: {
      name: 'qualified',
      color: 'brown',
      outline: true,
    },
  },
  {
    id: 'column-2',
    title: 'Contact made',
    name: 'contact made',
    color: rgbToHex(styleVars.blue500),
    items: [],
    collapsed: false,
    index: 1,
    status: {
      name: 'contact made',
      color: 'blue',
      outline: true,
    },
  },
  {
    id: 'column-3',
    title: 'Proposal made',
    name: 'proposal made',
    color: rgbToHex(styleVars.purple500),
    items: [],
    collapsed: false,
    index: 2,
    status: {
      name: 'proposal made',
      color: 'violet',
      outline: true,
    },
  },
  {
    id: 'column-4',
    title: 'Negotiation started',
    name: 'negotiation started',
    color: rgbToHex(styleVars.orange500),
    items: [],
    collapsed: false,
    index: 3,
    status: {
      name: 'negotiation started',
      outline: true,
      color: 'orange',
    },
  },
  {
    id: 'column-5',
    title: 'Won',
    name: 'won',
    color: rgbToHex(styleVars.green500),
    items: [],
    collapsed: false,
    index: 4,
    status: {
      name: 'won',
      color: 'green',
      outline: false,
    },
  },
  {
    id: 'column-6',
    title: 'Lost',
    name: 'lost',
    color: rgbToHex(styleVars.red500),
    items: [],
    collapsed: false,
    index: 5,
    status: {
      name: 'lost',
      color: 'red',
      outline: false,
    },
  },
]

export const dealsDetailsTimelinesWonItem: DealsDetailsProgressItem = {
  label: 'Won',
  background: rgbToHex(styleVars.green200),
  color: '#417211',
  disabled: true,
}

export const dealsDetailsTimelinesLostItem: DealsDetailsProgressItem = {
  label: 'Lost',
  background: rgbToHex(styleVars.red200),
  color: '#B51820',
  disabled: true,
}

export const dealsDetailsTimelines: DealsDetailsProgressItem[] = [
  {
    label: 'Qualified',
    background: rgbToHex(styleVars.mint500),
    color: rgbToHex(styleVars.white),
    disabled: false,
  },
  {
    label: 'Contact made',
    background: rgbToHex(styleVars.primary),
    color: rgbToHex(styleVars.white),
    disabled: false,
  },
  {
    label: 'Proposal made',
    background: rgbToHex(styleVars.purple500),
    color: rgbToHex(styleVars.white),
    disabled: false,
  },
  {
    label: 'Negotiation started',
    background: rgbToHex(styleVars.orange500),
    color: rgbToHex(styleVars.white),
    disabled: false,
  },
  {
    label: 'Won/lost',
    background: rgbToHex(styleVars.green200),
    color: '#417211',
    disabled: true,
    dropdown: [
      {
        label: 'Won',
        iconClass: 'success--text',
        icon: 'verified',
        item: dealsDetailsTimelinesWonItem,
      },
      {
        label: 'Lost',
        iconClass: 'error--text',
        icon: 'tmi-unverified',
        item: dealsDetailsTimelinesLostItem,
      },
    ],
  },
]

export const dealsTemplates: TemplateType[] = [
  {
    icon: 'tmi-file',
    title: 'Blank',
    text: 'Create a new board from scratch.',
    clickEvent: (): void => {
      openModal('dealsForm')
    },
  },
  {
    icon: 'tmi-sale',
    title: 'Sales',
    text: 'Track potential deals from initial contact to closing.',
    clickEvent: (): void => {
      openModal('templatePreview', {
        title: 'Sales template',
        backFunc: () => openTemplateModal(),
        nextFunc: () => openModal('dealsForm'),
        description: 'Enhance sales with dynamic lead and deal tracking.',
        image: require('@/assets/images/deals/template-image.svg'),
        statuses: dealsStatuses,
        statusesLabel: 'Deals stages',
      })
    },
  },
  {
    icon: 'tmi-business-card',
    title: 'Recruitment',
    text: 'Monitor candidate progress from application to final offer.',
    clickEvent: (): void => {
      openModal('templatePreview', {
        title: 'Recruitment template',
        backFunc: () => openTemplateModal(),
        nextFunc: () => openModal('dealsForm'),
        description: 'Streamline hiring from job posting to onboarding.',
        image: require('@/assets/images/deals/template-image-2.svg'),
        statuses: [
          {
            name: 'Applied',
            color: 'brown',
            outline: true,
          },
          {
            name: 'Screening',
            color: 'blue',
            outline: true,
          },
          {
            name: 'Interview',
            color: 'violet',
            outline: true,
          },
          {
            name: 'Offer',
            color: 'orange',
            outline: true,
          },
          {
            name: 'Hired',
            color: 'green',
            outline: false,
          },
          {
            name: 'Rejected',
            color: 'red',
            outline: false,
          },
        ],
        statusesLabel: 'Deals stages',
      })
    },
  },
  {
    icon: 'shopping_cart',
    title: 'Procurement',
    text: 'Simplify the process of managing your vendors and orders.',
    clickEvent: (): void => {
      openModal('templatePreview', {
        title: 'Procurement template',
        backFunc: () => openTemplateModal(),
        nextFunc: () => openModal('dealsForm'),
        description: 'Manage vendors and orders efficiently.',
        image: require('@/assets/images/deals/template-image-3.svg'),
        statuses: [
          {
            name: 'Requests',
            color: 'brown',
            outline: true,
          },
          {
            name: 'Sourcing',
            color: 'blue',
            outline: true,
          },
          {
            name: 'Purchasing',
            color: 'violet',
            outline: true,
          },
          {
            name: 'Receiving',
            color: 'orange',
            outline: true,
          },
          {
            name: 'Done',
            color: 'green',
            outline: false,
          },
          {
            name: 'Rejected',
            color: 'red',
            outline: false,
          },
        ],
        statusesLabel: 'Deals stages',
      })
    },
  },
  {
    icon: 'house',
    title: 'Real estate',
    text: 'Track the progress of each property.',
    clickEvent: (): void => {
      openModal('templatePreview', {
        title: 'Real estate template',
        backFunc: () => openTemplateModal(),
        nextFunc: () => openModal('dealsForm'),
        description: 'Track the progress of each property.',
        image: require('@/assets/images/deals/template-image-4.svg'),
        statuses: [
          {
            name: 'New leads',
            color: 'gray',
            outline: true,
          },
          {
            name: 'Lead qualified',
            color: 'blue',
            outline: true,
          },
          {
            name: 'Schedule a showing',
            color: 'violet',
            outline: true,
          },
          {
            name: 'Negotiation',
            color: 'orange',
            outline: true,
          },
          {
            name: 'Signing contact',
            color: 'brown',
            outline: true,
          },
          {
            name: 'Done',
            color: 'green',
            outline: false,
          },
          {
            name: 'Rejected',
            color: 'red',
            outline: false,
          },
        ],
        statusesLabel: 'Deals stages',
      })
    },
  },
  {
    icon: 'tmi-calculator-money',
    title: 'Expense tracking',
    text: 'Simplify expense recording and analysis.',
    clickEvent: (): void => {
      openModal('templatePreview', {
        title: 'Expense tracking template',
        backFunc: () => openTemplateModal(),
        nextFunc: () => openModal('dealsForm'),
        description: 'Simplify expense recording and analysis.',
        image: require('@/assets/images/deals/template-image-5.svg'),
        statuses: [
          {
            name: 'Payment requests',
            color: 'brown',
            outline: true,
          },
          {
            name: 'Approval',
            color: 'blue',
            outline: true,
          },
          {
            name: 'Payment',
            color: 'violet',
            outline: true,
          },
          {
            name: 'Receiving',
            color: 'orange',
            outline: true,
          },
          {
            name: 'Paid',
            color: 'green',
            outline: false,
          },
          {
            name: 'Canceled',
            color: 'red',
            outline: false,
          },
        ],
        statusesLabel: 'Deals stages',
      })
    },
  },
]

const firstPerson: PersonType = {
  uid: '1',
  firstName: 'Robert',
  lastName: 'Overit',
  fullName: 'Robert Overit',
  avatar: '',
  avatarColor: 'blue',
}

const secondPerson: PersonType = {
  uid: '2',
  firstName: 'Alley',
  lastName: 'Ranker',
  fullName: 'Alley Ranker',
  avatar: '',
  avatarColor: 'purple',
}

const thirdPerson: PersonType = {
  uid: '3',
  firstName: 'Cameron',
  lastName: 'Williamson',
  fullName: 'Cameron Williamson',
  avatar: '',
  avatarColor: 'green',
}

export const dealsTimelineItems: DealsDetailsTimelineItem[] = [
  {
    type: 'action',
    person: secondPerson,
    text: [
      'Added estimated close date ',
      {
        text: '28 Mar 2024',
        style: 'semi-bold',
      },
    ],
    date: '3 min ago',
  },
  {
    type: 'reply',
    person: thirdPerson,
    text: 'Hey, I can make it.',
    date: 'Yesterday, 12:30 am',
  },
  {
    type: 'action',
    person: firstPerson,
    text: [
      'Updated win probability to ',
      {
        style: 'semi-bold',
        text: '10%',
      },
    ],
    date: '12 Apr, 12:28 am',
  },
  {
    type: 'reply',
    person: thirdPerson,
    text: 'Textmagic number, you will get notified',
    date: '12 Apr, 12:30 am',
  },
  {
    type: 'reply',
    person: firstPerson,
    text: 'Send email after deal will be done. When a new incoming message reaches your virtual Textmagic number, you will get notified via a desktop alert and – if needed – via email and mobile app push notifications.',
    date: '12 Apr, 12:30 am',
  },
  {
    type: 'action',
    person: secondPerson,
    text: [
      'Updated the stage to ',
      {
        text: 'Qualified',
        style: 'semi-bold',
      },
    ],
    date: '12 Apr, 12:30 am',
  },
  {
    type: 'action',
    person: secondPerson,
    text: 'Created this deal',
    date: '12 Apr, 12:30 am',
  },
]

export const dealsChecklist = ref<KanbanCheckListItem[]>([
  {
    title: 'Send text message to Helen Ipkiss',
    checked: true,
  },
  {
    title: 'Mobile call to Helen Ipkiss',
    checked: true,
  },
  {
    title: 'Call to all team and onboard them',
    checked: false,
  },
  {
    title: 'Meeting with Helen Ipkiss',
    checked: true,
  },
  {
    title: 'Send actual proposal',
    checked: false,
  },
])

export const dealsAttachments = ref<KanbanAttachmentType[]>([
  {
    title: 'Screenshot_12.10.22_new.png',
    date: '23 Jan 2024, 3:43 am',
    file: 'image',
    url: require('@/assets/images/introduction/video-preview.png'),
  },
  {
    title: 'deposit_assistant_withdrawal',
    date: '22 Jan 2024, 4:42 pm',
    file: 'xls',
  },
  {
    title: 'Screenshot_12.10.22_new.png',
    date: '23 Jan 2024, 3:43 am',
    file: 'image',
    url: require('@/assets/images/introduction/video-preview.png'),
  },
  {
    title: 'deposit_assistant_withdrawal',
    date: '22 Jan 2024, 4:42 pm',
    file: 'xls',
  },
])
